body {
  margin: 0;
  font-family: 'Gothic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
}

.centered {
  position: fixed;
  /* or absolute */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

th,
td {
  color: red
}

@font-face {
  font-family: 'Gothic';
  src: local('Gothic'), url(./fonts/Gothic/Gothic-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ExtraBoldGothic';
  src: local('ExtraBoldGothic'), url(./fonts/ExtraBoldGothic/ExtraBoldGothic-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'BoldGothic';
  src: local('BoldGothic'), url(./fonts/BoldGothic/BoldGothic-Regular.ttf) format('truetype');
}


::-webkit-input-placeholder {
  /* Chrome */
  color: '#EFEFEF';
  font-size: 0.85em;
}

.btn {
  text-transform: unset !important;
}

*:focus {
  outline: none;
}

.container {
  max-width: 100%;
  width: 100%;
  background-color: white;
  font-family: 'Gothic';
  border: 2px solid rgb(239, 239, 239);
  border-radius: 7px;
  padding-top: 1em;
  padding-bottom: 1em;
}

.containerMobile {
  max-width: 95%;
  width: 95%;
  background-color: white;
  font-family: 'Gothic';
  border: 2px solid rgb(239, 239, 239);
  border-radius: 7px;
  padding-top: 1em;
  padding-bottom: 1em;
  margin: 0 auto;
}

.edit {
  margin: 0px auto;
  width: 100%;
  background-color: white;
  border-radius: 7px;
  font-family: 'Gothic';
}

.common {
  color: #191268;
  font-family: 'Gothic';
}

.InputField {
  font-family: 'Gothic';
  border: 2px solid;
  border-radius: 7px;
  font-weight: bold;
  font-size: 0.85em;
  height: 2.7em;
  padding-inline-start: 1em;
}

.InputFieldTable {
  font-family: 'Gothic';
  border: none;
  height: 100%;
  width: 100%;
  text-align: center;
}

.InputFieldMobile {
  font-family: 'Gothic';
  border: 2px solid;
  border-radius: 7px;
  font-weight: bold;
  font-size: 0.85em;
  height: 2.7em;
  padding-inline-start: 1em;
  margin: 0 auto;
}

.Loading {
  position: absolute;
  left: 50%;
  top: 60%;
  z-index: 2;
}

.LoadingMobile {
  position: absolute;
  left: 20%;
  top: 50%;
  z-index: 2;
}

.h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gothic';
}